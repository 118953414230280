<template>
  <b10-base>
    <b10-toolbar
      :title="title"
    >
      <v-btn
        slot="right"
        icon
        @click.stop="clickSubmit($refs.form)"
      >
        <v-icon>{{ $vuetify.icons.values.check }}</v-icon>
      </v-btn>
    </b10-toolbar>
    <b10-page-content
      :padding="3"
    >
      <ValidationObserver
        ref="form"
      >
        <solicitud-material-form
          v-if="dbReady"
          :id="routeParams.idsolicitud_material"
          :idparte-trabajo="routeParams.idparte_trabajo"
          @change-values="changeFormData"
        />
      </ValidationObserver>
    </b10-page-content>
  </b10-base>
</template>

<script>
import { formPageMixin } from '@/mixins/formPageMixin'
import SolicitudMaterialForm from '../components/SolicitudMaterialForm'
import Data from './SolicitudMaterialEditData'
import { toDate } from '@/utils/date'

export default {
  components: {
    SolicitudMaterialForm
  },
  mixins: [formPageMixin],
  async created () {
    await this.initStore()
    await this.initDB()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      this.title = 'Modificar solicitud'
    },
    changeFormData (formData) {
      this.formData = formData
    },
    async _submitForm () {
      // caso especial de campos fecha -> convertir a Date si se modificaron
      if (this.formData.fsolicitada_entrega && typeof(this.formData.fsolicitada_entrega) === 'string') {
        this.formData.fsolicitada_entrega = toDate(this.formData.fsolicitada_entrega)
      }
      await Data.update(this, this.routeParams.idsolicitud_material, this.formData)
    },
    async submitForm () {
      await this._submitForm()
      await this.$dirty.modified(this.$dirty.ENTITIES.remote.SolicitudMaterial, this.routeParams.solicitud_material)
      this.$appRouter.go(-1)
    },
  },
}
</script>
