
export default {
  async update (Vue, id, formData) {
    await Vue.$offline.solicitudMaterial.updateSync({
      idsolicitud_material: id,
      // fsolicitud no se puede modificar
      fsolicitud: formData.fsolicitud,
      fsolicitada_entrega: formData.fsolicitada_entrega,
      descripcion: formData.descripcion,
      observaciones: formData.observaciones,
      atencion_de: formData.atencion_de,
      idgrupo_empleado: formData.idgrupo_empleado,
    })
  },
}
